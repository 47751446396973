import React, { useState, useEffect } from 'react';
import axios from 'axios';

// Function to fetch company data
const fetchCompanyData = async () => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({ "useremail": "" });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow"
  };

  try {
    const response = await fetch("https://casaahaanahotels.com/search_company_data", requestOptions);
    return await response.json();
  } catch (error) {
    console.error("Error fetching company data:", error);
    return [];
  }
};

const AssessmentTable = () => {
  const [assessments, setAssessments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingUserEmail, setLoadingUserEmail] = useState(false);
  const [loadingAdminEmail, setLoadingAdminEmail] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [selectedEmails, setSelectedEmails] = useState("");

  // Fetch assessment data
  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      try {
        const response = await axios.get('https://casaahaanahotels.com/list_assessments');
        if (isMounted) {
          setAssessments(response.data.result);
          setLoading(false);
        }
      } catch (error) {
        if (isMounted) setLoading(false);
        console.error("Error fetching assessments:", error);
      }
    };

    fetchData();
    return () => {
      isMounted = false;
    };
  }, []);

  // Fetch company data
  useEffect(() => {
    let isMounted = true;
    const loadCompanies = async () => {
      const data = await fetchCompanyData();
      if (isMounted) {
        setCompanies(data);
      }
    };

    loadCompanies();
    return () => {
      isMounted = false;
    };
  }, []);

  // Handle the <select> for admin emails
  const handleChange = (event) => {
    let emailString = "";
    const selectedCompany = event.target.value;

    // Find the matching company
    const company = companies.find((c) => c.companyname === selectedCompany);

    if (company) {
      if (Array.isArray(company.assessmentRecipient)) {
        emailString = company.assessmentRecipient.join(",");
      } else if (typeof company.assessmentRecipient === "string") {
        emailString = company.assessmentRecipient;
      } else {
        emailString = "";
      }
    }
    setSelectedEmails(emailString);
  };

  // Helper: get today's date as mm/dd/yyyy
  const getCurrentDate = () => {
    const d = new Date();
    return `${(d.getMonth() + 1).toString().padStart(2, "0")}/${d.getDate().toString().padStart(2, "0")}/${d.getFullYear()}`;
  };

  // Build the user email body (fully independent HTML)
  const buildUserBody = (payload) => {
    return `
<body style="font-family:Arial,sans-serif;background-color:#f4f4f4;margin:0;padding:0">
  <div class="container" style="width:96%;margin:30px auto;background:#fff;padding:20px;box-shadow:0 0 15px rgba(0,0,0,.2);border-radius:8px">
    <div class="header" style="text-align:center;margin-bottom:10px">
      <img src="https://starbehaviors.com/uploadimage/star_behaviors_llc_cover.jpeg" alt="Star Behaviors Logo" style="width:auto;height:100px">
    </div>
    <h2 style="text-align:center;margin-top:0">Star Behaviors Assessment Results - USER</h2>
    <p style="font-size:16px;">
      Hello ${payload.drivername},<br/>
      Thank you for completing your assessment. 
      We look forward to speaking with you soon. 
    </p>

    <div class="form-field" style="background-color:#f9f9f9;padding:8px;margin-bottom:10px;display:flex;border:1px solid #ddd;border-radius:4px">
      <label style="font-weight:700;width:40%;margin-right:5px;font-size: 16px;">Date:</label>
      <span style="width:60%;text-align:left;font-size: 16px;">${payload.emailDate}</span>
    </div>
    <div class="form-field" style="background-color:#f9f9f9;padding:8px;margin-bottom:10px;display:flex;border:1px solid #ddd;border-radius:4px">
      <label style="font-weight:700;width:40%;margin-right:5px;font-size: 16px;">To:</label>
      <span style="width:60%;text-align:left;font-size: 16px;">${payload.assessmentRecipient}</span>
    </div>

    <div class="form-field" style="background-color:#f9f9f9;padding:8px;margin-bottom:10px;display:flex;border:1px solid #ddd;border-radius:4px">
      <label style="font-weight:700;width:40%;margin-right:5px;font-size: 16px;">Name:</label>
      <span style="width:60%;text-align:left;font-size: 16px;">${payload.drivername}</span>
    </div>
    <div class="form-field" style="background-color:#f9f9f9;padding:8px;margin-bottom:10px;display:flex;border:1px solid #ddd;border-radius:4px">
      <label style="font-weight:700;width:40%;margin-right:5px;font-size: 16px;">Address:</label>
      <span style="width:60%;text-align:left;font-size: 16px;">${payload.driveraddress}</span>
    </div>
    <div class="form-field" style="background-color:#f9f9f9;padding:8px;margin-bottom:10px;display:flex;border:1px solid #ddd;border-radius:4px">
      <label style="font-weight:700;width:40%;margin-right:5px;font-size: 16px;">Phone:</label>
      <span style="width:60%;text-align:left;font-size: 16px;">${payload.driverphone}</span>
    </div>
    <div class="form-field" style="background-color:#f9f9f9;padding:8px;margin-bottom:10px;display:flex;border:1px solid #ddd;border-radius:4px">
      <label style="font-weight:700;width:40%;margin-right:5px;font-size: 16px;">Email:</label>
      <span style="width:60%;text-align:left;font-size: 16px;">${payload.useremail}</span>
    </div>
    <div class="form-field" style="background-color:#f9f9f9;padding:8px;margin-bottom:10px;display:flex;border:1px solid #ddd;border-radius:4px">
      <label style="font-weight:700;width:40%;margin-right:5px;font-size: 16px;">Assessment Completed:</label>
      <span style="width:60%;text-align:left;font-size: 16px;">${payload.assessment_date}</span>
    </div>
    <div class="form-field" style="background-color:#f9f9f9;padding:8px;margin-bottom:10px;display:flex;border:1px solid #ddd;border-radius:4px">
      <label style="font-weight:700;width:30%;margin-right:5px;font-size: 16px;">Score:</label>
      <span style="width:70%;text-align:left;font-size: 16px;">
        <img src="https://starbehaviors.com/uploadimage/${payload.stars}.png" />
      </span>
    </div>

    <p style="font-size:16px;">
      We appreciate your time and effort. A representative will contact you soon.
    </p>
    <div class="powered" style="text-align:center;margin-top:40px;font-style:italic">Powered by Star Behaviors.</div>
  </div>
</body>
    `;
  };

  // Build the admin email body (fully independent HTML)
  const buildAdminBody = (payload) => {
    return `
<body style="font-family:Arial,sans-serif;background-color:#f4f4f4;margin:0;padding:0">
  <div class="container" style="width:96%;margin:30px auto;background:#fff;padding:20px;box-shadow:0 0 15px rgba(0,0,0,.2);border-radius:8px">
    <div class="header" style="text-align:center;margin-bottom:10px">
      <img src="https://starbehaviors.com/uploadimage/star_behaviors_llc_cover.jpeg" alt="Star Behaviors Logo" style="width:auto;height:100px">
    </div>
    <h2 style="text-align:center;margin-top:0">Star Behaviors Assessment Results - ADMIN</h2>
    <p style="font-size:16px;">
      Hello Team,<br/>
      Below are the assessment details of a recent candidate. 
      Please reach out to them as soon as possible.
    </p>

    <div class="form-field" style="background-color:#f9f9f9;padding:8px;margin-bottom:10px;display:flex;border:1px solid #ddd;border-radius:4px">
      <label style="font-weight:700;width:40%;margin-right:5px;font-size: 16px;">Date:</label>
      <span style="width:60%;text-align:left;font-size: 16px;">${payload.emailDate}</span>
    </div>
    <div class="form-field" style="background-color:#f9f9f9;padding:8px;margin-bottom:10px;display:flex;border:1px solid #ddd;border-radius:4px">
      <label style="font-weight:700;width:40%;margin-right:5px;font-size: 16px;">To:</label>
      <span style="width:60%;text-align:left;font-size: 16px;">${payload.assessmentRecipient}</span>
    </div>

    <div class="form-field" style="background-color:#f9f9f9;padding:8px;margin-bottom:10px;display:flex;border:1px solid #ddd;border-radius:4px">
      <label style="font-weight:700;width:40%;margin-right:5px;font-size: 16px;">Name:</label>
      <span style="width:60%;text-align:left;font-size: 16px;">${payload.drivername}</span>
    </div>
    <div class="form-field" style="background-color:#f9f9f9;padding:8px;margin-bottom:10px;display:flex;border:1px solid #ddd;border-radius:4px">
      <label style="font-weight:700;width:40%;margin-right:5px;font-size: 16px;">Address:</label>
      <span style="width:60%;text-align:left;font-size: 16px;">${payload.driveraddress}</span>
    </div>
    <div class="form-field" style="background-color:#f9f9f9;padding:8px;margin-bottom:10px;display:flex;border:1px solid #ddd;border-radius:4px">
      <label style="font-weight:700;width:40%;margin-right:5px;font-size: 16px;">Phone:</label>
      <span style="width:60%;text-align:left;font-size: 16px;">${payload.driverphone}</span>
    </div>
    <div class="form-field" style="background-color:#f9f9f9;padding:8px;margin-bottom:10px;display:flex;border:1px solid #ddd;border-radius:4px">
      <label style="font-weight:700;width:40%;margin-right:5px;font-size: 16px;">Email:</label>
      <span style="width:60%;text-align:left;font-size: 16px;">${payload.useremail}</span>
    </div>
    <div class="form-field" style="background-color:#f9f9f9;padding:8px;margin-bottom:10px;display:flex;border:1px solid #ddd;border-radius:4px">
      <label style="font-weight:700;width:40%;margin-right:5px;font-size: 16px;">Assessment Completed:</label>
      <span style="width:60%;text-align:left;font-size: 16px;">${payload.assessment_date}</span>
    </div>
    <div class="form-field" style="background-color:#f9f9f9;padding:8px;margin-bottom:10px;display:flex;border:1px solid #ddd;border-radius:4px">
      <label style="font-weight:700;width:30%;margin-right:5px;font-size: 16px;">Score:</label>
      <span style="width:70%;text-align:left;font-size: 16px;">
        <img src="https://starbehaviors.com/uploadimage/${payload.stars}.png" />
      </span>
    </div>

    <p style="font-size:16px;">
      Feel free to follow up with the candidate directly. 
    </p>
    <div class="powered" style="text-align:center;margin-top:40px;font-style:italic">Powered by Star Behaviors.</div>
  </div>
</body>
    `;
  };

  // Actually handle the email sending
  const sendEmail = async (assessment, isAdmin) => {
    const { score, timestamp, driver_details } = assessment;
    const formattedDate = new Date(timestamp).toLocaleDateString("en-US");

    // Common fields
    const payload = {
      assessmentRecipient: selectedEmails,
      drivername: `${driver_details.FirstName} ${driver_details.LastName}`,
      driveraddress: `${driver_details.Street || ""} ${driver_details.CurrentCity || ""} ${driver_details.CurrentState || ""}`.trim() || "Not Provided",
      driverphone: driver_details.PhoneNumber || "Not Provided",
      useremail: driver_details.DriverEmail,
      assessment_date: formattedDate,
      stars: score,
      emailDate: getCurrentDate(), // For "Date:" field in the email
    };

    // Use the external mail API endpoint
    const mailApiUrl = "https://bakemywords.com/mailchimp/sendmail.php";

    if (isAdmin) {
      // Send to company admin
      const adminEmail = assessment.company_details?.adminemail || "";
      if (!adminEmail) {
        alert("No admin email found for this company.");
        return;
      }
      setLoadingAdminEmail(true);

      // Build admin body
      const emailBody = buildAdminBody(payload);

      // New request body for the mail API
      const requestBody = {
        email: adminEmail,
        name: "Company Admin",
        subject: "Star Behaviors Assessment Results - ADMIN",
        message: emailBody
      };

      console.log("Admin Request Body:", requestBody);

      try {
        // Use mode: "no-cors" to bypass CORS errors during local testing.
        await fetch(mailApiUrl, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
         mode: "no-cors",
          body: JSON.stringify(requestBody)
        });
        // Since the response is opaque in no-cors mode, assume success.
        alert("Successfully sent Company Admin Mail");
      } catch (err) {
        console.error(err);
        alert("Error sending Company Admin Mail");
      } finally {
        setLoadingAdminEmail(false);
      }
    } else {
      // Send to user
      const userEmail = driver_details.DriverEmail;
      if (!userEmail) {
        alert("No user email found for this candidate.");
        return;
      }
      setLoadingUserEmail(true);

      // Build user body
      const emailBody = buildUserBody(payload);

      // New request body for the mail API
      const requestBody = {
        email: userEmail,
        name: payload.drivername,
        subject: "Star Behaviors Assessment Results - USER",
        message: emailBody
      };

      console.log("User Request Body:", requestBody);

      try {
        // Use mode: "no-cors" here as well.
        await fetch(mailApiUrl, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          mode: "no-cors",
          body: JSON.stringify(requestBody)
        });
        // Assume success due to opaque response.
        alert("Successfully sent User Email");
      } catch (err) {
        console.error(err);
        alert("Error sending User Email");
      } finally {
        setLoadingUserEmail(false);
      }
    }
  };

  return (
    <div>
      <h2 style={{ textAlign: "center" }}>Assessment List</h2>

      {/* Company Selection */}
      <div>
        <select onChange={handleChange}>
          <option value="">Select a company</option>
          {companies.map((company) => (
            <option key={company.id} value={company.companyname}>
              {company.companyname}
            </option>
          ))}
        </select>
      </div>

      {loading ? (
        <p>Loading...</p>
      ) : (
        <table>
          <thead>
            <tr>
              <th>Full Name</th>
              <th>Email</th>
              <th>Company Name</th>
              <th>Star Score</th>
              <th>Assessment Completed</th>
              <th>Validity</th>
              <th>Send Driver Mail</th>
              <th>Send Company Admin Mail</th>
              <th>View</th>
            </tr>
          </thead>
          <tbody>
            {assessments?.map((assessment, index) => {
              if (assessment?.driverid && assessment?.score) {
                const formattedDate = new Date(assessment.timestamp).toLocaleDateString("en-US");
                const validityDate = new Date(assessment.validity).toLocaleDateString("en-US");
                const companyName = assessment?.company_details?.companyname || "N/A";
                const driverName = `${assessment?.driver_details?.FirstName || "N/A"} ${assessment?.driver_details?.LastName || "N/A"}`;
                const driverEmail = assessment?.driver_details?.DriverEmail || "N/A";

                return (
                  <tr key={`${assessment.assessementid}-${index}`}>
                    <td>{driverName}</td>
                    <td>{driverEmail}</td>
                    <td>{companyName}</td>
                    <td>
                      <img
                        src={`https://starbehaviors.com/uploadimage/${assessment.score}.png`}
                        alt={`Score: ${assessment.score}`}
                      />
                    </td>
                    <td>{formattedDate}</td>
                    <td>{validityDate}</td>
                    <td>
                      <button
                        onClick={() => sendEmail(assessment, false)}
                        disabled={loadingUserEmail}
                      >
                        {loadingUserEmail ? "Sending..." : "Send User Mail"}
                      </button>
                    </td>
                    <td>
                      <button
                        onClick={() => sendEmail(assessment, true)}
                        disabled={loadingAdminEmail}
                      >
                        {loadingAdminEmail ? "Sending..." : "Send Company Admin Mail"}
                      </button>
                    </td>
                    <td>
                      <a
                        href={`https://sb.starbehaviors.com/landingpage/showassessment/${assessment.assessementid}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View
                      </a>
                    </td>
                  </tr>
                );
              }
              return null;
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default AssessmentTable;
