import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../../config/serverApiConfig';
import axios from 'axios';
import styles from './styles.module.css';

function LandingPage() {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('landingpage/login');
  };

  const handleLogout1 = () => {
    localStorage.removeItem('token1');
    navigate('landingpage/login');
  };

  const [header, setHeader] = useState('');
  const [content, setContent] = useState('');
  const [image, setImage] = useState('');
  const [offers, setOffers] = useState([]);
  const [cta, setCta] = useState('');
  const [lastSegment, setLastSegment] = useState('');

  // Construct partner logo URL
  const image_file = 'https://starbehaviors.com/uploadimage/' + image;

  // Random background image logic
  const images = [
    'https://sb.starbehaviors.com/imgs/gettyimages-1.jpg',
    'https://sb.starbehaviors.com/imgs/gettyimages-2.jpg',
    'https://sb.starbehaviors.com/imgs/gettyimages-3.jpg',
    'https://sb.starbehaviors.com/imgs/gettyimages-4.jpg',
    'https://sb.starbehaviors.com/imgs/gettyimages-5.jpg',
    'https://sb.starbehaviors.com/imgs/gettyimages-6.jpg',
    'https://sb.starbehaviors.com/imgs/gettyimages-7.jpg',
    'https://sb.starbehaviors.com/imgs/gettyimages-8.jpg'
  ];

  const getRandomImage = () => {
    const randomIndex = Math.floor(Math.random() * images.length);
    return images[randomIndex];
  };

  const randomImage = getRandomImage();

  // Remove token after 1 second if present
  const remove = () => {
    if (localStorage.getItem('token')) {
      localStorage.removeItem('token');
      window.location.reload();
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      remove();
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, []);

  // Load data when component mounts
  useEffect(() => {
    const pathname = window.location.pathname;
    const segments = pathname.split('/');
    const segment = segments[segments.length - 1];
    setLastSegment(segment);
    localStorage.setItem('reg', segment);

    axios
      .post(API_BASE_URL + '/sb_getlandingpage', {
        usercode: segment ? segment : '22c4693b-c80a-4785-82ef-08e0faa905b1'
      })
      .then(response => {
        const userData = response.data.user_data[0];
        setHeader(userData.adminpageheader);
        setContent(userData.adminpagecontent);
        setOffers(userData.aadminpageoffers);
        setCta(userData.adminpagecta);
        setImage(userData.imageFile);

        localStorage.setItem('patnerImg', userData.imageFile);
        localStorage.setItem('partnerurl', userData.admin_site_url);
        localStorage.setItem('companyname', userData.companyname);

        setTimeout(logout, 1000);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  function logout() {
    const logoutEl = document.getElementById('logout1');
    if (logoutEl) logoutEl.innerHTML = '';
  }

  return (
    <div className={styles.main_container}>
      <div className="position-relative d-flex flex-column justify-content-center align-items-center">
        {/* Hero section with random background image */}
        <div
          className="hero-image new-hero-image"
          style={{ backgroundImage: `url(${randomImage})` }}
        >
          <div className="main-card">
            <div className="card border-2 text-center p-4">
              <div className="mb-5">
                <img
                  className="rounded-3"
                  src={image_file}
                  width="180"
                  height="90"
                  alt="Partner Logo"
                />
              </div>
              <div className="d-flex flex-column">
                <Link to={`/landingpage/register/${lastSegment}`}>
                  <button className="btn btn-primary text-uppercase">
                    Register
                  </button>
                </Link>
                <span className="my-1">- or -</span>
                <Link to="/landingpage/login">
                  <button className="btn btn-outline-secondary text-uppercase">
                    Login
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/* Main Content */}
        <div className="container">
          <div className="main-content" style={{ marginTop: '10%' }}>
            <div className="main-content-header-section mt-5 mb-4 m-auto">
              <div className="text-center">
                <h6 className="mb-3">
                  <strong id="hed">{header}</strong>
                </h6>
              </div>
              <p
                id="con"
                dangerouslySetInnerHTML={{ __html: content }}
              ></p>
            </div>

            <div className="offering-list-card card border-2 p-2 m-auto">
              <h6 className="call-to-action text-center">
                <strong>What Do We Offer?</strong>
              </h6>
              <ul className="offering-list" id="offering">
                {Array.isArray(offers) ? (
                  offers.map((offer, index) => (
                    <li className="offering" key={index}>
                      {offer}
                    </li>
                  ))
                ) : (
                  <li className="offering">No offerings to display</li>
                )}
              </ul>
            </div>

            <p className="call-to-action-sub-text text-center mt-3">
              <strong id="lend">{cta}</strong>
            </p>
            <p className="text-center">
              <strong>
                <Link to={`/landingpage/register/${lastSegment}`}>
                  Start by Completing our Online Assessment and Application
                  Today!
                </Link>
              </strong>
            </p>
          </div>
        </div>

        {/* Footer section with the same random background image (optional) */}
        <div
          className="footer-image"
          style={{ backgroundImage: `url(${randomImage})` }}
        ></div>
      </div>
    </div>
  );
}

export default LandingPage;
